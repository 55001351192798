import { ActionHelper } from "@solidwhite/vis-base";

class Actions extends ActionHelper {
  constructor() {
    super({ singular: "home" });
  }
}

const actions = new Actions();

export default actions;
