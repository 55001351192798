import React from "react";

const ImprintText = () => {
  return (
    <div>
      <p>
        SIEGENIA-AUBI KG
        <br />
        Industriestraße 1 - 3<br />
        57234 Wilnsdorf-Niederdielfen
        <br />
        GERMANY
      </p>

      <p>
        Authorised representative shareholder:
        <br />
        Wieland Frank (Managing Partner)
        <br />
        Tel.: +49 271 3931-0
        <br />
        Fax: +49 271 3931-333
        <br />
        E-Mail: info@siegenia.com
        <br />
        Register Entry: Entry in the Commercial Register
        <br />
        Court of registration: Siegen District Court
        <br />
        Registered No: HRA 3741
      </p>

      <p>
        VAT Identification No:
        <br />
        VAT ID No. DE126 569 009
      </p>

      <p>
        WEEE-Registration numbers
        <br />
        SIEGENIA-AUBI KG: DE97604302
        <br />
        KFV Karl Fliether GmbH & Co. KG: DE 30640754
      </p>

      <p>
        Person responsible for content in accordance with § 55 Paragraph 2 RStV
        (German Broadcasting Treaty):
        <br />
        Katja Schreiber
        <br />
        Marketing and Communications Management
        <br />
        SIEGENIA-AUBI KG
        <br />
        Industriestraße 1 - 3<br />
        57234 Wilnsdorf
        <br />
        GERMANY
      </p>
    </div>
  );
};

export default ImprintText;
