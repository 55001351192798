import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const downloadLink = process.env.REACT_APP_APPLICATION_DOWNLOAD_LINK;
  const downloadLinkMac = process.env.REACT_APP_APPLICATION_DOWNLOAD_LINK_MAC;
  const headerText = "SIEGENIA world";
  const downloadTextWindows = "Download Windows-App";
  const downloadTextMac = "Download iOS-App";

  return (
    <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
      <div className={"container-fluid justify-content-left mt-5"}>
        <div className="card-narrow">
          <div className={"d-flex mb-4"}>
            <h2 className={"page-title ml-0"}>Home</h2>
          </div>

          <div className="card card-wide rounded shadow mb-3">
            <div className="row m-0">
              <div className="col m-0 p-0"></div>
            </div>

            <div className="p-5">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h4 style={{ fontWeight: 700 }}>{headerText}</h4>
                <div className="col d-flex justify-content-center">
                  <a href={downloadLink}>
                    <button
                      style={{ color: "black", fontWeight: 600 }}
                      type="button"
                      id="downloadAppButton"
                      className="btn btn-primary mr-3"
                    >
                      {downloadTextWindows}
                    </button>
                  </a>
                  {downloadLinkMac && (
                    <a
                      href={downloadLinkMac}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        style={{ color: "black", fontWeight: 600 }}
                        type="button"
                        id="downloadMacAppButton"
                        className="btn btn-primary"
                      >
                        {downloadTextMac}
                      </button>
                    </a>
                  )}
                </div>
              </div>

              <div className="row mt-5 mb-5">
                <div className="col d-flex justify-content-center">
                  <video
                    width="850"
                    height="550"
                    controls
                    preload="metadata"
                    poster={process.env.PUBLIC_URL + "/placeholder.png"}
                  >
                    <source
                      src={
                        process.env.PUBLIC_URL + "/Anmeldung SIEGENIA world.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>

              {/* <div className="row mt-5">
                <div className="col d-flex justify-content-center">
                  <a href={"/"}>
                    <button
                      type="button"
                      id="downloadMacAppButton"
                      className="btn btn-primary"
                    >
                      FAQ
                    </button>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={"ml-3"}
        style={{ flex: "1 1 auto", position: "relative", minHeight: "3rem" }}
      >
        <div style={{ position: "absolute", bottom: "1rem", left: "0" }}>
          <Link to={"/imprint"} className={"imprint-link"}>
            Imprint - Privacy Policy - Legal
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
