import React from "react";

const TermsOfUse = () => {
  return (
    <div>
      <p>
        Please find the{" "}
        <a
          href="https://www.solidwhite.de/vishow_endnutzerbedingungen.pdf"
          target="_blank"
          rel="noopener noreferrer">
          VISHOW App End User Terms of
          SOLID WHITE here.
        </a>
      </p>
    </div>
  );
};

export default TermsOfUse;
