import React from "react";

const PrivacyText = () => {
  return (
    <div>
      <p>
        Please find our privacy policy here:
        <br />
        <a
          href="https://www.siegenia.com/de/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.siegenia.com/de/privacy
        </a>
      </p>
    </div>
  );
};

export default PrivacyText;
