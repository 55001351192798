import React from "react";

import { Home } from "./";

export default {
  list: {
    path: "downloadapp",
    icon: "VDC_IconFont_Download",
    title: "Home",
    private: false,
    restricted: "",
    level: 0,
    component: (props) => <Home />,
  },
};
